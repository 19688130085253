import React, { ReactElement } from 'react';
import moment from 'moment';
import { Link } from '@zola/zola-ui/src/components/Link';
import dayjs, { ConfigType as DayjsConfigType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  REQUIRED,
  EMAIL,
  ZIP,
  PHONE,
  PASSWORD,
  SLUG,
  TIME,
  URL,
  DATE_WITHIN_VALID_RANGE,
  DATE_AFTER_NOW,
  GREATER_THAN_ZERO,
  GREATER_THAN_OR_EQUAL_TO_ZERO,
  NUMBER,
  INSTAGRAM_HANDLE,
  maxLength,
  MAX_LENGTH_5,
  MAX_LENGTH_15,
  MAX_LENGTH_20,
  MAX_LENGTH_25,
  MAX_LENGTH_30,
  MAX_LENGTH_35,
  MAX_LENGTH_50,
  MAX_LENGTH_40,
  MAX_LENGTH_60,
  MAX_LENGTH_80,
  MAX_LENGTH_100,
  MAX_LENGTH_150,
  MAX_LENGTH_200,
  MAX_LENGTH_250,
  MAX_LENGTH_400,
  MAX_LENGTH_500,
  MAX_LENGTH_800,
  MAX_LENGTH_1000,
  MAX_LENGTH_1500,
  MAX_LENGTH_2000,
  MAX_LENGTH_5000,
  URL_REGEX,
  DATE_REGEX,
} from '@zola/zola-ui/src/components/Form/util/validations';

const MAX_LENGTH_4000 = maxLength(4000);

dayjs.extend(utc);

export const INITIAL_REGEX = /^[a-zA-Z]{1}$/;
export const COLOR_REGEX = /^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/;

export enum validationMsg {
  DATE = 'Invalid date',
  NOT_ZOLA = 'Your Zola registry not showing up? Call us at 408-657-9652 to add it.',
  INITIAL_LETTER = 'Invalid character',
  URL_MSG = 'Invalid URL',
}

export const COLOR = (value: string | number): boolean => {
  const normalizedValue = value?.toString();
  return COLOR_REGEX.test(normalizedValue);
};

export const DATE = (value: string): validationMsg.DATE | undefined => {
  if (typeof value === 'object') {
    return undefined;
  }
  return value && !DATE_REGEX.test(value) ? validationMsg.DATE : undefined;
};

Number.isInteger =
  Number.isInteger ||
  // eslint-disable-next-line func-names
  function (value: number): boolean {
    // eslint-disable-next-line no-restricted-globals
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };

export const fakeValid = (): undefined => undefined;

const ZOLA_REGEX = /zola/i;
const ZOLA_DOT_COM_REGEX = /zola\.com/i;

export const NOT_ZOLA = (value: string | undefined): validationMsg.NOT_ZOLA | undefined => {
  if (!value) {
    return undefined;
  }
  if (ZOLA_REGEX.test(value) && ZOLA_DOT_COM_REGEX.test(value)) {
    return validationMsg.NOT_ZOLA;
  }
  return undefined;
};

export const VALIDATE_URL = (urlValue: string | undefined): validationMsg.URL_MSG | undefined => {
  if (urlValue && URL_REGEX.test(urlValue)) {
    return validationMsg.URL_MSG;
  }
  return undefined;
};

export const CHECK_CURRENT_WEDDING_DATE = (
  value: DayjsConfigType | undefined,
  input: { currentlySelectedDate?: Date; registryIsPublic?: boolean }
): ReactElement | undefined => {
  const currentlySelectedDate = moment(input.currentlySelectedDate);
  const { registryIsPublic } = input;
  const date = moment();
  const weddingDateHasPassed = currentlySelectedDate.isBefore(date, 'day');
  if (registryIsPublic && weddingDateHasPassed) {
    return (
      <>
        Your wedding date has passed. Need help updating it to a future date? Reach out to us{' '}
        <Link
          inline
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.zola.com/hc/en-us/requests/new?ticket_form_id=360000823452"
        >
          here
        </Link>
        .
      </>
    );
  }
  return undefined;
};

export const MOMENT_DATE_AFTER_NOW = (value: moment.MomentInput): string | undefined => {
  const date = moment.utc(value, 'MM/DD/YYYY');
  const now = moment().format('MM/DD/YYYY');
  const nowInUTC = moment.utc(now, 'MM/DD/YYYY');

  if (!date.isValid()) {
    return 'Hmm… this does not look like a valid date.';
  }
  if (date.isBefore(nowInUTC)) {
    return 'Hmm… this looks like a past date.';
  }
  if (date.isAfter(moment().add(10, 'y'), 'year')) {
    return `Date cannot be after ${moment().add(10, 'y').format('YYYY')}`;
  }
  return undefined;
};

export const MOMENT_DATE_WITHIN_VALID_RANGE = (value: moment.MomentInput): string | undefined => {
  if (moment.isMoment(value)) {
    if (value.isBefore('2012')) {
      return 'Date cannot be before 2012';
    }
    if (value.isAfter(moment().add(10, 'y'), 'year')) {
      return `Date cannot be after ${moment().add(10, 'y').format('YYYY')}`;
    }
    return undefined;
  }
  const date = moment.utc(value, 'MM/DD/YYYY');
  if (date.isBefore('2012')) {
    return 'Date cannot be before 2012';
  }
  if (date.isAfter(moment().add(10, 'y'), 'year')) {
    return `Date cannot be after ${moment().add(10, 'y').format('YYYY')}`;
  }
  return undefined;
};

export const INITIAL_LETTER = (
  value: string | undefined
): validationMsg.INITIAL_LETTER | undefined =>
  value && !INITIAL_REGEX.test(value) ? validationMsg.INITIAL_LETTER : undefined;

export {
  DATE_AFTER_NOW,
  DATE_REGEX,
  DATE_WITHIN_VALID_RANGE,
  EMAIL,
  GREATER_THAN_OR_EQUAL_TO_ZERO,
  GREATER_THAN_ZERO,
  INSTAGRAM_HANDLE,
  maxLength,
  NUMBER,
  PASSWORD,
  PHONE,
  REQUIRED,
  SLUG,
  TIME,
  URL_REGEX,
  URL,
  ZIP,
  MAX_LENGTH_5,
  MAX_LENGTH_15,
  MAX_LENGTH_20,
  MAX_LENGTH_25,
  MAX_LENGTH_30,
  MAX_LENGTH_35,
  MAX_LENGTH_50,
  MAX_LENGTH_40,
  MAX_LENGTH_60,
  MAX_LENGTH_80,
  MAX_LENGTH_100,
  MAX_LENGTH_150,
  MAX_LENGTH_200,
  MAX_LENGTH_250,
  MAX_LENGTH_400,
  MAX_LENGTH_500,
  MAX_LENGTH_800,
  MAX_LENGTH_1000,
  MAX_LENGTH_1500,
  MAX_LENGTH_2000,
  MAX_LENGTH_4000,
  MAX_LENGTH_5000,
};
